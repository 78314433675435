import Head from "next/head";

const PageHead = ({ title, isHomePage = false}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="La Institución Educativa Colegio Menfis, con 25 años de trayectoria en Chinchiná, ofrece una educación lúdico-pedagógica centrada en el desarrollo integral de niños y jóvenes. Nuestra propuesta educativa se distingue por el enfoque en el bilingüismo, adaptándose a las demandas del siglo XXI." />
        <meta name="keywords" content="Menfis, Colegio Menfis, Centro Educativo Menfis, escuela en Chinchiná, educación bilingüe, lúdico-pedagógica, desarrollo integral, world schooling, world schooling colombia, educación en Chinchiná, colegio, Institución Educativa, enseñanza en Chinchiná, coffee region, Chinchiná, Caldas, Colombia, Latin America, South America" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />

        { isHomePage && (
          <>
            {/* <!-- Open Graph Meta Tags --> */}
            <meta property="og:title" content="Colegio Menfis | Educación con enfoque Bilingüe y Lúdico-Pedagógica en Chinchiná" />
            <meta property="og:description" content="La Institución Educativa Colegio Menfis en Chinchiná ofrece una educación lúdico-pedagógica con un enfoque en el bilingüismo, adaptada a las necesidades del siglo XXI." />
            <meta property="og:image" content="colegiomenfis.org/images/banner/menfis_banner_2.jpg" />
            <meta property="og:url" content="colegiomenfis.org" />
            <meta property="og:type" content="website" />
          </>
        )}
      </Head>
    </>
  );
};

export default PageHead;
