import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";

import logo from "../../../public/images/logo/logo.png";

import Nav from "../Nav";

const HeaderFour = ({ gapSpaceBetween, sticky, navigationEnd, container }) => {
  const { mobile, setMobile, } =
    useAppContext();
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrolled = window.scrollY;
      if (scrolled > 180) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className={`rbt-header-wrapper ${gapSpaceBetween} ${sticky} ${
          isSticky ? "rbt-sticky" : ""
        }`}
      >
        <div className={`${container}`}>
          <div className={`mainbar-row ${navigationEnd} align-items-center`}>
            <div className="header-left">
              <div className="logo">
                <Link href="/">
                  <Image
                    src={logo}
                    width={152}
                    height={50}
                    priority={true}
                    alt="Menfis Logo"
                  />
                </Link>
              </div>
            </div>
            <div className="rbt-main-navigation d-none d-xl-block">
              <Nav />
            </div>
            <div className="header-right">
              <div className="rbt-btn-wrapper d-none d-xl-block ">
                <Link
                  className="rbt-btn hover-icon-reverse radius-round enrolment-btn"
                  href="/enrollment"
                >
                  <div className="icon-reverse-wrapper">
                    <span className="btn-text">Admisiones</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </div>
                </Link>
                <Link
                  className="rbt-btn hover-icon-reverse btn-border-gradient radius-round"
                  href="https://familia.mattilda.io/"
                  target="_blank"
                >
                  <div className="icon-reverse-wrapper">
                    <span className="btn-text">Pagos</span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                    <span className="btn-icon">
                      <i className="feather-arrow-right"></i>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="mobile-menu-bar d-block d-xl-none">
                <div className="hamberger">
                  <button
                    className="hamberger-button rbt-round-btn"
                    onClick={() => setMobile(!mobile)}
                  >
                    <i className="feather-menu"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderFour;
