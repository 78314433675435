import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.png";

import CopyRight from "./CopyRight";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";

const FooterOne = ({ bgColor }) => {
  return (
    <>
      <footer
        className={`rbt-footer footer-style-1 ${
          bgColor ? bgColor : "bg-color-white"
        } overflow-hidden`}
      >
        <div className="footer-top">
          <div className="container">
            {FooterData &&
              FooterData.footerTwo.map((footer, index) => (
                <div className="row g-5" key={index}>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <div className="logo d-flex justify-content-center">
                        <Link href="/">
                          <Image
                            src={logo}
                            width={152}
                            height={50}
                            alt="Menfis Logo"
                          />
                        </Link>
                      </div>
                      <ul className="social-icon social-default justify-content-center">
                        {footer.socialLink.map((value, innerIndex) => (
                          <li key={innerIndex}>
                            <Link href={value.link} target={value.target}>
                              <i className={value.icon}></i>
                            </Link>
                          </li>
                        ))}
                      </ul>
                      <h5 className="ft-title text-center mt--15">¿Listo para formar parte de la comunidad Menfisiana?</h5>
                      <div className="contact-btn text-center">
                        <Link
                          className="rbt-btn hover-icon-reverse radius-round"
                          href="/enrollment"
                        >
                          <div className="icon-reverse-wrapper">
                            <span className="btn-text">Admisiones - 2025</span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <SingleFooter
                    classOne="col-lg-2 col-md-6 col-sm-6 col-12 mt--30"
                    title="Links de interés"
                    footerType={footer.usefulLinks}
                  />
                  <SingleFooter
                    classOne="col-lg-2 col-md-6 col-sm-6 col-12"
                    title="Colegio Menfis"
                    footerType={footer.ourCompany}
                  />

                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <h5 className="ft-title">Información de contacto</h5>
                      <ul className="ft-link">
                        <li>
                          <span>Teléfono: </span>
                          <Link href="tel:3103548371">{footer.phone}</Link>
                        </li>
                        <li>
                          <span> Whatsapp: </span>
                          <Link href="https://wa.me/+573103548371" target="_blank">{footer.phone}</Link>
                        </li>
                        <li>
                          <span>E-mail:</span>{" "}
                          <Link href="mailto:secretaria@colegiomenfis.org">
                            {footer.mail}
                          </Link>
                        </li>
                        <li>
                          <span>Ubicación:</span> {footer.address}
                        </li>
                      </ul>
                    </div>
                    <div className="contact-btn mt--20">
                      <Link
                        className="rbt-btn hover-icon-reverse btn-border-gradient radius-round"
                        href="/contact"
                      >
                        <div className="icon-reverse-wrapper">
                          <span className="btn-text">Contáctanos</span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                          <span className="btn-icon">
                            <i className="feather-arrow-right"></i>
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <CopyRight />
      </footer>
    </>
  );
};

export default FooterOne;
